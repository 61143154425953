<template>
    <div>
        <SideBar />
        <DashboardArea />
    </div>
</template>

<script>
import SideBar from '../dashboard/SideBar';
import DashboardArea from '../dashboard/DashboardArea';

export default {
    name: 'Dashboard',
    components: {
        SideBar,
        DashboardArea
    }
}
</script>