<template>
    <div>
        <Preloader v-if="isLoading" />
        <div class="view-wrapper">
            <div class="page-content-wrapper is-size-default">
                <div class="page-content is-relative">
                    <div class="page-title has-text-centered">
                        <div class="title-wrap">
                            <h1 class="title">
                                <span>Dashboard</span>
                            </h1>
                        </div>
                    </div>
                    <div class="personal-dashboard">
                        <div class="columns is-multiline">
                            <div class="column is-12">
                                <div class="dashboard-header">
                                    <div class="v-avatar is-xl">
                                        <img :src="avatar" alt="" class="avatar">
                                    </div>
                                    <div class="user-meta">
                                        <h3 class="title is-4 is-narrow is-bold">
                                            Welcome back, {{ companyName }}
                                        </h3>
                                        <p class="light-text">It's really nice to see you again</p>
                                    </div>
                                    <div class="user-action">
                                        <div v-if="inWaitlist">
                                            <h3 class="title is-4 is-narrow"> You are in the waitlist</h3>
                                            <p class="light-text">Please wait for us to approve your account</p>
                                        </div>
                                        <div class="join-waitlist" v-else>
                                            <h3 class="title is-4 is-narrow"> Want to start using our services?</h3>
                                            <p class="light-text">You can click the button below to get into our waiting list</p>
                                            <button class="button v-button is-primary is-elevated is-rounded" @click="goToWaitlist()">Join Waitlist</button>
                                        </div>
                                        <!-- Turn off the data submission function termporarily -->
                                        <!-- <a href="/data-submit" class="action-link">
                                            View Tasks
                                        </a> -->
                                    </div>
                                    <div class="cta h-hidden-tablet-p">
                                        <div class="inverted-text">
                                            <iconify-icon class="iconify" icon="pepicons-pencil:crown"></iconify-icon>
                                            <p class="white-text">Have any comments or encounter any issues?</p>
                                            <a href="/contact" class="link inverted-text">Contact Us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Turn off the data submission function termporarily -->
                            <!-- <div class="column is-10">
                                <div class="dashboard-card has-margin-bottom">
                                    <div class="card-head">
                                        <h3 class="dark-inverted">Active Projects</h3>
                                        <a href="/data-submit" class="action-link" tabindex="0">View All</a>
                                    </div>
                                    <div class="active-projects" v-if="projects.length > 0">
                                        <div class="media-flex-center"
                                                v-for="project in projects"
                                                :key="project.id"
                                        >
                                            <div class="v-avatar is-medium">
                                                <img :src="project_avatars[project.type]" alt="" class="avatar is-square">
                                            </div>
                                            <div class="flex-meta">
                                                <span>{{ project.name }}</span>
                                                <span>Updated {{ project.last_updated }}</span>
                                            </div>
                                            <div class="flex-end">
                                                <div class="status">
                                                    <span>{{ project.status }}</span>
                                                </div>
                                                <div class="end-action dropdown">
                                                    <a tabindex="0" class="is-trigger dropdown-trigger" aria-label="View more actions" @click="toggleDropdown(project.id)">
                                                        <iconify-icon class="iconify" icon="tabler:dots-vertical"></iconify-icon>
                                                    </a>
                                                    <div class="dropdown-menu" v-show="isDropdownVisible[project.id]">
                                                        <div class="dropdown-content">
                                                            <a href="#" class="dropdown-item is-media" tabindex="0" @click="toggleDropdown(project.id)" role="menuitem">
                                                                <div class="icon">
                                                                    <iconify-icon class="iconify" icon="tabler:edit"></iconify-icon>
                                                                </div>
                                                                <div class="meta">
                                                                    <span>Edit</span>
                                                                    <span>View and Edit Project</span>
                                                                </div>
                                                            </a>
                                                            <hr class="dropdown-divider">
                                                            <a href="#" class="dropdown-item is-media" tabindex="0" @click="toggleDropdown(project.id)">
                                                                <div class="icon">
                                                                    <iconify-icon class="iconify" icon="tabler:trash"></iconify-icon>
                                                                </div>
                                                                <div class="meta">
                                                                    <span>Delete</span>
                                                                    <span>Remove Project</span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="no-active-projects" v-else>
                                        <p class="light-text">No active projects</p>
                                        <button type="button" class="button" @click="goTo('/data-submit')">
                                            <span class="icon">
                                                <iconify-icon class="iconify" icon="fluent:add-16-filled">
                                                </iconify-icon>
                                            </span>
                                            <span> Start a new project </span>
                                        </button>
                                    </div>
                                </div>
                            </div> -->

                            <!-- Temporarily add sample reports directly into the dashboard page -->
                            <div class="column is-12">
                                <SampleRerportViewer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    

</template>

<script>
import { auth, db } from '@/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import SampleRerportViewer from '../sample-report-viewer/SampleReportViewer.vue';
import Preloader from '../layout/Preloader.vue';

export default {
    name: 'DashboardArea',
    data() {
        return {
            isLoading: true,
            isUserLoading: true,
            userProfile: null,
            companyName: '',
            role: '',
            email: '',
            uid: '',
            avatar: '',
            inWaitlist: false,
            // isDropdownVisible: {
                // 1: false,
                // 2: false,
                // 3: false,
            // },
            // project_avatars: {
                // Genome: require('@/assets/images/mainpage/genetic.png'),
                // Epic: require('@/assets/images/mainpage/epigenetic.png'),
                // Index: require('@/assets/images/mainpage/index.png'),
            // },
            // projects: [
                // {
                //     id: 1,
                //     type: 'Genome',
                //     name: 'Genome Project 1',
                //     status: 'Under Review',
                //     last_updated: '2 days ago',
                // },
                // {
                //     id: 2,
                //     type: 'Epic',
                //     name: 'Epic Project 2',
                //     status: 'Completed',
                //     last_updated: '2 days ago',
                // },
                // {
                //     id: 3,
                //     type: 'Index',
                //     name: 'Index Project 3',
                //     status: 'Under Review',
                //     last_updated: '2 days ago',
                // },
            // ]
        }
    },
    components: {
        Preloader,
        SampleRerportViewer,
    },
    methods: {
        checkAuthStatus() {
            this.isUserLoading = true;
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    // this.isLoggedIn = true;
                    const userRef = doc(db, 'users', user.uid);
                    getDoc(userRef).then((doc) => {
                        if (doc.exists()) {
                            this.userProfile = doc.data();
                            this.companyName = this.userProfile.companyName ? this.userProfile.companyName : "Company Name";
                            this.role = this.userProfile.role ? this.userProfile.role : "Role";
                            this.email = this.userProfile.email ? this.userProfile.email : "Email";
                            this.uid = this.userProfile.uid ? this.userProfile.uid : "UID";
                            this.inWaitlist = this.userProfile.inWaitlist ? this.userProfile.inWaitlist : false;
                            this.avatar = this.userProfile.avatar ? this.userProfile.avatar : require("@/assets/images/dna3.jpg");
                        } else {
                            console.error("No such document!");
                        }
                    }).catch((error) => {
                        console.error("Error getting document:", error);
                    });
                } else {
                    // this.isLoggedIn = false;
                    this.userProfile = null;
                    this.companyName = "Company Name";
                    this.avatar = require("@/assets/images/dna3.jpg");
                    console.error("No user is signed in.");
                    // this.$router.push("/login");
                }
            });
            this.isUserLoading = false;
        },

        goToWaitlist() {
            this.$router.push('/join-waitlist');
        },

        // toggleDropdown(index) {
        //     this.isDropdownVisible[index] = !this.isDropdownVisible[index];
        // },

        // handleClickOutside(event) {
        //     const dropdowns = document.querySelectorAll('.dropdown-menu');
        //     const dots = document.querySelectorAll('.dropdown-trigger');
        //     dropdowns.forEach((dropdown, index) => {
        //         if (this.isDropdownVisible[index] && !dropdown.contains(event.target) && !dots[index].contains(event.target)) {
        //             this.isDropdownVisible[index] = false;
        //         }
        //     });
        // },

        goTo(link) {
            this.$router.push(link);
        }
        
    },
    created() {
        this.checkAuthStatus();
        if (!this.isUserLoading) {
            setTimeout(() => {
                this.isLoading = false
            }, 1500)
        }        
    },
//     mounted() {
//         document.addEventListener('click', this.handleClickOutside);
//     },
//     beforeUnmount() {
//         document.removeEventListener('click', this.handleClickOutside);
//     }
}
</script>

<style lang="scss">
@import "@/assets/css/dashboard.scss";
.join-waitlist {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;

    p {
        margin-bottom: 1rem;
    }
    button {
        margin-top: 1rem;

        &:hover {
            background-color: $primary !important;
        }
    }
}
</style>