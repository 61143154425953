<template>
    <div>
        <Preloader v-if="isLoading" />
        <div class="view-wrapper">
            <div class="page-content-wrapper is-size-default">
                <div class="page-content is-relative">
                    <div class="page-title has-text-centered">
                        <div class="title-wrap">
                            <h1 class="title">
                                <span>Join Waitlist</span>
                            </h1>
                        </div>
                    </div>
                    <form novalidate class="form-layout" @submit.prevent="submit">
                        <div class="form-outer">
                            <div class="form-header stuck-header">
                                <div class="form-header-inner">
                                    <div class="left">
                                        <h3>Request to Join Waitlist</h3>
                                    </div>
                                    <div class="right">
                                        <div class="buttons">
                                            <a href="/dashboard" class="button v-button is-light">
                                                <span class="icon">
                                                    <iconify-icon class="iconify" icon="ci:arrow-left-lg"></iconify-icon>
                                                </span>
                                                <span>Cancel</span>
                                            </a>
                                            <button type="submit" class="button v-button is-primary is-raised">
                                                <span>Submit</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-body">
                                <div class="form-fieldset">
                                    <div class="fieldset-heading">
                                        <h4>Person in Contact</h4>
                                        <p>This person will be the main contact for the waitlist.</p>
                                    </div>
                                    <div class="columns is-multiline">
                                        <div class="column is-6">
                                            <div class="field">
                                                <label class="label">First Name <span>*</span></label>
                                                <div class="control has-icon">
                                                    <input type="text" class="input" placeholder autocomplete="given-name" v-model="firstname" />
                                                    <iconify-icon class="iconify form-icon" icon="lucide:user"></iconify-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column is-6">
                                            <div class="field">
                                                <label class="label">Last Name <span>*</span></label>
                                                <div class="control has-icon">
                                                    <input type="text" class="input" placeholder autocomplete="family-name" v-model="lastname" />
                                                    <iconify-icon class="iconify form-icon" icon="lucide:user"></iconify-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column is-6">
                                            <div class="field">
                                                <label class="label">Job Level <span>*</span></label>
                                                <div class="multiselect">
                                                    <div class="multiselect-wrapper" tabindex="0" role="combobox" @click.stop="toggleDropdown('jobLevel')">
                                                        <div class="multiselect-placeholder" v-if="jobLevel === ''">Job Level</div>
                                                        <div class="multiselect-single-label" v-else>
                                                            <div class="multiselect-single-label-text">{{ jobLevel }}</div>
                                                        </div>
                                                        <div class="multiselect-caret">
                                                            <iconify-icon class="iconify" icon="lucide:chevron-down"></iconify-icon>
                                                        </div>
                                                    </div>
                                                    <div class="multiselect-dropdown is-hidden" tabindex="1" id="jobLevel">
                                                        <ul class="multiselect-options" id="multiselect-options" role="listbox">
                                                            <div 
                                                                class="multiselect-option"
                                                                role="option"
                                                                v-for="job in jobLevels"    
                                                                :key="job"
                                                                @click.stop="handleSelectJobLevels(job)"
                                                                :class="{ 'is-selected': jobLevel === job }"
                                                            >
                                                                <span>{{ job }}</span>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column is-6">
                                            <div class="field">
                                                <label class="label">Job Function <span>*</span></label>
                                                <div class="multiselect">
                                                    <div class="multiselect-wrapper" tabindex="0" role="combobox" @click.stop="toggleDropdown('jobFunction')">
                                                        <div class="multiselect-placeholder" v-if="jobFunction === ''">Job Function</div>
                                                        <div class="multiselect-single-label" v-else>
                                                            <div class="multiselect-single-label-text">{{ jobFunction }}</div>
                                                        </div>
                                                        <div class="multiselect-caret">
                                                            <iconify-icon class="iconify" icon="lucide:chevron-down"></iconify-icon>
                                                        </div>
                                                    </div>
                                                    <div class="multiselect-dropdown is-hidden" tabindex="1" id="jobFunction">
                                                        <ul class="multiselect-options" id="multiselect-options" role="listbox">
                                                            <div 
                                                                class="multiselect-option"
                                                                role="option"
                                                                v-for="func in jobFunctions"    
                                                                :key="func"
                                                                @click.stop="handleSelectJobFunctions(func)"
                                                                :class="{ 'is-selected': jobFunction === func }"
                                                            >
                                                                <span>{{ func }}</span>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column is-6">
                                            <div class="field">
                                                <label class="label">Country/Region <span>*</span></label>
                                                <div class="multiselect">
                                                    <div class="multiselect-wrapper" tabindex="0" role="combobox" @click.stop="toggleDropdown('country')">
                                                        <div class="multiselect-placeholder" v-if="country === ''">Country/Region</div>
                                                        <div class="multiselect-single-label" v-else>
                                                            <div class="multiselect-single-label-text">{{ country }}</div>
                                                        </div>
                                                        <div class="multiselect-caret">
                                                            <iconify-icon class="iconify" icon="lucide:chevron-down"></iconify-icon>
                                                        </div>
                                                    </div>
                                                    <div class="multiselect-dropdown is-hidden" tabindex="1" id="country">
                                                        <ul class="multiselect-options" id="multiselect-options" role="listbox">
                                                            <div 
                                                                class="multiselect-option"
                                                                role="option"
                                                                v-for="c in countries"    
                                                                :key="c"
                                                                @click.stop="handleSelectCountry(c)"
                                                                :class="{ 'is-selected': country === c }"
                                                            >
                                                                <span>{{ c }}</span>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column is-6">
                                            <div class="field">
                                                <label class="label">Work Phone Number<span>*</span></label>
                                                <div class="control has-icon">
                                                    <input type="text" class="input" placeholder autocomplete="tel" v-model="phone" />
                                                    <iconify-icon class="iconify form-icon" icon="lucide:phone"></iconify-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column is-12">
                                            <div class="field">
                                                <label class="label">Work Email <span>*</span></label>
                                                <div class="control has-icon">
                                                    <input type="email" class="input" placeholder autocomplete="email" inputmode="email" v-model="email" />
                                                    <iconify-icon class="iconify form-icon" icon="lucide:mail"></iconify-icon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-fieldset">
                                    <div class="fieldset-heading">
                                        <h4>Company Information</h4>
                                        <p>Information about the company that is requesting to join the waitlist.</p>
                                    </div>
                                    <div class="columns is-multiline">
                                        <div class="column is-6">
                                            <div class="field">
                                                <label class="label">Company Name <span>*</span></label>
                                                <div class="control has-icon">
                                                    <input type="text" class="input" placeholder autocomplete="organization" v-model="companyName" />
                                                    <iconify-icon class="iconify form-icon" icon="lucide:building"></iconify-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="column is-6">
                                            <div class="field">
                                                <label class="label">Company Phone <span>*</span></label>
                                                <div class="control has-icon">
                                                    <input type="text" class="input" placeholder autocomplete="mobile" v-model="companyPhone" />
                                                    <iconify-icon class="iconify form-icon" icon="lucide:phone"></iconify-icon>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="column is-6">
                                            <div class="field">
                                                <label class="label">Company Size <span>*</span></label>
                                                <div class="control">
                                                    <div class="multiselect">
                                                        <div class="multiselect-wrapper" tabindex="0" role="combobox" @click.stop="toggleDropdown('companySize')">
                                                            <div class="multiselect-placeholder" v-if="companySize === ''">Select a size</div>
                                                            <div class="multiselect-single-label" v-else>
                                                                <div class="multiselect-single-label-text">{{ companySize }}</div>
                                                            </div>
                                                            <div class="multiselect-caret">
                                                                <iconify-icon class="iconify" icon="lucide:chevron-down"></iconify-icon>
                                                            </div>
                                                        </div>
                                                        <div class="multiselect-dropdown is-hidden" tabindex="1" id="companySize">
                                                            <ul class="multiselect-options" id="multiselect-options" role="listbox">
                                                                <li class="multiselect-option" role="option" @click="handleSelectSize('1-10 Employees')" :class="{ 'is-selected': companySize === '1-10 Employees' }">
                                                                    <span>1-10 Employees</span>
                                                                </li>
                                                                <li class="multiselect-option" role="option" @click="handleSelectSize('11-50 Employees')" :class="{ 'is-selected': companySize === '11-50 Employees' }">
                                                                    <span>11-50 Employees</span>
                                                                </li>
                                                                <li class="multiselect-option" role="option" @click="handleSelectSize('51-100 Employees')" :class="{ 'is-selected': companySize === '51-100 Employees' }">
                                                                    <span>51-100 Employees</span>
                                                                </li>
                                                                <li class="multiselect-option" role="option" @click="handleSelectSize('100+ Employees')" :class="{ 'is-selected': companySize === '100+ Employees' }">
                                                                    <span>100+ Employees</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column is-12">
                                            <div class="field">
                                                <label class="label">Company Type <span>*</span></label>
                                                <div class="control">
                                                    <div class="multiselect">
                                                        <div class="multiselect-wrapper" tabindex="0" role="combobox" @click.stop="toggleDropdown('companyType')">
                                                            <div class="multiselect-placeholder" v-if="companyType === ''">Select a type</div>
                                                            <div class="multiselect-single-label" v-else>
                                                                <div class="multiselect-single-label-text">{{ companyType }}</div>
                                                            </div>
                                                            <div class="multiselect-caret">
                                                                <iconify-icon class="iconify" icon="lucide:chevron-down"></iconify-icon>
                                                            </div>
                                                        </div>
                                                        <div class="multiselect-dropdown is-hidden" tabindex="1" id="companyType">
                                                            <ul class="multiselect-options" id="multiselect-options" role="listbox">
                                                                <div 
                                                                    class="multiselect-option"
                                                                    role="option"
                                                                    v-for="labRole in accountRoles['lab']"    
                                                                    :key="labRole"
                                                                    @click.stop="handleSelectRole('lab', labRole)"
                                                                    :class="{ 'is-selected': companyType === labRole }"
                                                                >
                                                                    <span>{{ labRole }}</span>
                                                                </div>
                                                                <hr class="dropdown-divider">
                                                                <div 
                                                                    class="multiselect-option"
                                                                    role="option"
                                                                    v-for="userRole in accountRoles['user']"    
                                                                    :key="userRole"
                                                                    @click.stop="handleSelectRole('user', userRole)"
                                                                    :class="{ 'is-selected': companyType === userRole }"
                                                                >
                                                                    <span>{{ userRole }}</span>
                                                                </div>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="column is-12">
                                            <div class="field">
                                                <label class="label">Company Website</label>
                                                <div class="control has-icon">
                                                    <input type="url" class="input" placeholder autocomplete="url" v-model="companyWebsite" />
                                                    <iconify-icon class="iconify form-icon" icon="streamline:web"></iconify-icon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-fieldset">
                                    <div class="fieldset-heading">
                                        <h4>Additional Information</h4>
                                        <p>Any additional information you would like to provide.</p>
                                    </div>
                                    <div class="columns is-multiline">
                                        <div class="column is-12">
                                            <div class="field">
                                                <label class="label">Special Information</label>
                                                <div class="control">
                                                    <textarea class="textarea" v-model="addtionalInfo" rows="4" placeholder="Tell us any details you'd like use to know..." autocomplete="off" autocapitalize="off" spellcheck="true"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { accountRoles } from '../../services/accountRoles';
import { auth, db } from '@/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import Preloader from '../layout/Preloader.vue';
import { jobLevels, jobFunctions, countries } from '../../services/jobInfo';

export default {
    name: 'JoinWaitlist',
    data() {
        return {
            firstname: '',
            lastname: '',
            jobLevel: '',
            jobFunction: '',
            country: '',
            phone: '',
            email: '',
            companyName: '',
            companySize: '',
            companyType: '',
            companyWebsite: '',
            addtionalInfo: '',
            userProfile: null,
            isLoading: false,
            accountRoles,
            jobLevels,
            jobFunctions,
            countries,
        };
    },
    components: {
        Preloader,
    },
    methods: {
        checkAuthStatus() {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    const userRef = doc(db, 'users', user.uid);
                    getDoc(userRef).then((doc) => {
                        if (doc.exists()) {
                            this.userProfile = doc.data();
                            this.firstname = this.userProfile.firstname ? this.userProfile.firstname : "";
                            this.lastname = this.userProfile.lastname ? this.userProfile.lastname : "";
                            this.jobLevel = this.userProfile.jobLevel ? this.userProfile.jobLevel : "";
                            this.jobFunction = this.userProfile.jobFunction ? this.userProfile.jobFunction : "";
                            this.country = this.userProfile.country ? this.userProfile.country : "";
                            this.phone = this.userProfile.phone ? this.userProfile.phone : "";
                            this.companyName = this.userProfile.companyName ? this.userProfile.companyName : "";
                            this.email = this.userProfile.email ? this.userProfile.email : "";
                        } else {
                            console.error("No such document!");
                        }
                    }).catch((error) => {
                        console.error("Error getting document:", error);
                    });
                } else {
                    this.userProfile = null;
                    this.companyName = "";
                    this.role = "";
                    this.email = "";
                    console.error("You are not logged in. Please log in to access this page.");
                    // alert("You are not logged in. Please log in to access this page.");
                    // this.$router.push("/login");
                }
            });
        },

        toggleDropdown(id) {
            const dropdown = document.getElementById(id);
            dropdown.classList.toggle('is-hidden');
        },

        handleSelectSize(value) {
            if (value !== '1-10 Employees' && value !== '11-50 Employees' && value !== '51-100 Employees' && value !== '100+ Employees') {
                this.companySize = 'Invalid Size';
                return;
            }
            this.companySize = value;
            this.toggleDropdown('companySize');
        },

        handleSelectRole(type, value) {
            if (!this.accountRoles[type].includes(value)) {
                this.companyType = 'Invalid Role';
                return;
            }
            this.companyType = value;
            this.toggleDropdown('companyType');
        },

        handleClickOutside(event) {
            const dropdowns = document.querySelectorAll('.multiselect-dropdown');
            dropdowns.forEach((dropdown) => {
                if (!dropdown.contains(event.target)) {
                    dropdown.classList.add('is-hidden');
                }
            });
        },

        formValidate() {
            if (this.firstname === '' || this.lastname === '' || this.jobLevel === '' || this.jobFunction === '' || this.country === '' || this.phone === '' || this.email === '' || this.companyName === '' || this.companySize === '' || this.companyType === '') {
                alert('Please fill out all required (*) fields');
                return false;
            }

            if (this.email.indexOf('@') === -1 || this.email.indexOf('.') === -1) {
                alert('Invalid email');
                return false;
            }

            return true;
        },

        addToWaitlist() {
            const user = auth.currentUser;
            const userRef = doc(db, 'users', user.uid);
            setDoc(userRef, { inWaitlist: true }, { merge: true }).catch((error) => {
                console.error("Error adding document: ", error);
            });
            
            const waitlistRef = doc(db, 'waitlist', user.uid);
            const waitlistData = {
                firstName: this.firstname,
                lastName: this.lastname,
                jobLevel: this.jobLevel,
                jobFunction: this.jobFunction,
                country: this.country,
                email: this.email,
                phone: this.phone,
                companyName: this.companyName,
                companySize: this.companySize,
                companyType: this.companyType,
                companyWebsite: this.companyWebsite,
                addtionalInfo: this.addtionalInfo,
                submitAt: new Date(), 
            };
            console.log("Waitlist data: ", waitlistData);
            setDoc(waitlistRef, waitlistData).then(() => {
                console.log("Waitlist added successfully");
                alert("You have been added to the waitlist. Please wait for us to approve your account.");
                this.$router.push('/dashboard');
            }).catch((error) => {
                console.error("Error adding waitlist: ", error);
                alert("Error adding waitlist. Please try again later.");
            });

        },

        submit() {
            if (!this.formValidate()) {
                return;
            }
            const user = auth.currentUser;
            if (user) {
                this.isLoading = true;
                setTimeout(() => {
                    this.isLoading = false;
                    this.addToWaitlist();
                }, 2000);
            } else {
                alert('Please login to submit the form');
                this.$router.push('/login');
            }
        },
    },
    mounted() {
        this.checkAuthStatus();
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    beforeRouteLeave(to, from, next) {
        this.size = '';
        this.role = '';
        document.removeEventListener('click', this.handleClickOutside);
        next();
    },
};
</script>